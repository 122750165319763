<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Representantes">
          <cadastrar-representante
            v-if="acao === 'cadastrar'"
            @close="alterarAcao"
          />
          <editar-representante
            v-else-if="acao === 'editar'"
            @close="alterarAcao"
            :representanteASerEditado="representanteASerEditado"
          />
          <listar-representante
            v-else-if="acao === 'listar'"
            @cadastrar="alterarAcao"
            @editar="editarRepresentante"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarRepresentante from '@/components/patio/Representante/ListarRepresentante'
import CadastrarRepresentante from '@/components/patio/Representante/CadastrarRepresentante'
import EditarRepresentante from '@/components/patio/Representante/EditarRepresentante'

export default {
  name: 'Representantes',

  components: {
    ListarRepresentante,
    CadastrarRepresentante,
    EditarRepresentante,
  },

  data: function () {
    return {
      page: {
        title: 'Gerenciamento de Representantes',
      },
      breadcrumbs: [
        {
          text: 'Logística',
          disabled: true,
        },
        {
          text: 'Cotas',
          disabled: true,
        },
        {
          text: 'Representantes',
          disabled: false,
        },
      ],
      // cadastrar, listar, editar
      acao: 'listar',
      representanteASerEditado: {},
    }
  },
  methods: {
    alterarAcao: function (acao = 'listar') {
      this.acao = acao
    },
    editarRepresentante: function (representante) {
      this.representanteASerEditado = representante
      this.acao = 'editar'
    },
  },
}
</script>
