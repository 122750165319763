<template>
  <v-container>
    <base-form-wizard
      @on-complete="save"
      @close="close"
      title="Cadastro de Representante"
      :hasError="hasError"
    >
      <tab-content
        title="Informações pessoais"
        icon="ti-id-badge"
        :before-change="() => validate('formInformacaoPessoal')"
      >
        <form-informacao-pessoal
          :colaborador="representante.colaborador"
          ref="formInformacaoPessoal"
        />
      </tab-content>
      <tab-content
        title="Informações de acesso"
        icon="ti-user"
        :before-change="() => validate('formInformacaoAcesso')"
      >
        <form-informacao-acesso
          :colaborador="representante.colaborador"
          ref="formInformacaoAcesso"
      /></tab-content>
      <tab-content
        title="Regiões"
        icon="ti-world"
        :before-change="() => validate('formRegioes')"
        ><form-regioes
          :representanteTerritorial="representanteTerritorial"
          ref="formRegioes"
        />
      </tab-content>
      <tab-content
        title="Segurança"
        icon="ti-lock"
        :before-change="
          () =>
            !!representante.colaborador.grupo &&
            representante.colaborador.perfis.length > 0
        "
      >
        <v-card class="pa-8 mb-4">
          <v-card-text>
            <v-row>
              <v-col>
                <v-autocomplete
                  label="Grupo"
                  v-model="representante.colaborador.grupo"
                  :items="grupos"
                  item-value="public_id"
                  item-text="nome"
                />
              </v-col>
              <v-col>
                <v-autocomplete
                  label="Perfis"
                  multiple
                  v-model="representante.colaborador.perfis"
                  :items="perfisEmpresaAtual"
                  item-text="nome"
                  item-value="cod"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </tab-content>
    </base-form-wizard>
  </v-container>
</template>

<script>
import { TabContent } from 'vue-form-wizard'
import { mapActions, mapState } from 'vuex'
import { cpf } from 'cpf-cnpj-validator'
import Perfil from '@/utils/perfil'

import BaseFormWizard from '@/components/commonComponents/BaseFormWizard'
import FormInformacaoPessoal from './form/FormInformacaoPessoal'
import FormInformacaoAcesso from './form/FormInformacaoAcesso'
import FormRegioes from './form/FormRegioes'

export default {
  name: 'CadastrarRepresentante',

  components: {
    BaseFormWizard,
    TabContent,
    FormInformacaoPessoal,
    FormInformacaoAcesso,
    FormRegioes,
  },

  data: function () {
    return {
      hasError: false,

      representante: {
        colaborador: {
          grupo: '',
          public_id: '',
          user: {},
          perfis: [],
        },
      },
      representanteTerritorial: {},
      formRepresentanteTerritorial: {
        regioes: [],
      },
    }
  },

  computed: {
    ...mapState('gerencial', ['grupos']),
    ...mapState('auth', ['empresaAtual']),

    perfisEmpresaAtual() {
      return Object.entries(Perfil)
        .map(([nome, cod]) => ({
          nome,
          cod,
        }))
        .filter((i) => this.empresaAtual.tiposContrato.includes(i.cod))
    },
    cleanCPF: function () {
      return cpf.strip(this.representante.colaborador.user.cpf)
    },
  },

  async created() {
    await this.getGrupos()
  },

  methods: {
    ...mapActions('gerencial', ['addColaborador', 'getGrupos']),
    ...mapActions('patio', [
      'cadastrarRepresentante',
      'cadastrarRepresentanteTerritorial',
    ]),
    ...mapActions('template', ['successMessage', 'errorMessage']),

    close: function () {
      this.$emit('close')
    },
    save: async function () {
      const colaboradorJaCadastrado = this.representante.colaborador.public_id

      this.formatRepresentanteTerritorialFormBeforeSubmit()

      if (colaboradorJaCadastrado) {
        try {
          await this.saveRepresentante()
        } catch (e) {
          this.errorMessage(e.response.data)
        }
      } else {
        try {
          await this.saveColaborador()
          await this.saveRepresentante()
        } catch (e) {
          this.errorMessage(e.response.data)
        }
      }
    },

    saveRepresentante: async function () {
      try {
        let representantePublicID = this.representante.public_id

        if (!representantePublicID) {
          const representante = await this.cadastrarRepresentante({
            colaborador: this.representante.colaborador.public_id,
          })

          representantePublicID = representante.public_id
          this.representante.public_id = representantePublicID
        }

        await this.cadastrarRepresentanteTerritorial({
          representante: representantePublicID,
          ...this.formRepresentanteTerritorial,
        })

        this.successMessage('Representante cadastrado(a) com sucesso!')
        this.close()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    saveColaborador: async function () {
      try {
        const colaborador = await this.addColaborador({
          user: {
            cpf: this.cleanCPF,
            username: this.representante.colaborador.user.email,
            name: this.representante.colaborador.user.name,
            email: this.representante.colaborador.user.email,
            celular: this.representante.colaborador.user.celular,
            password: this.representante.colaborador.user.password,
            nacionalidade: this.representante.colaborador.user.nacionalidade,
          },
          grupo: this.representante.colaborador.grupo,
          perfis: this.representante.colaborador.perfis,
        })

        Object.assign(this.representante.colaborador, colaborador)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    validate: async function (form) {
      const isValid = await this.$refs[form].validate()

      this.hasError = !isValid

      return isValid
    },

    formatRepresentanteTerritorialFormBeforeSubmit: function () {
      this.formRepresentanteTerritorial.regioes =
        this.representanteTerritorial.regioes.map((regiao) => ({
          regiao: regiao.sigla,
          tipo_frete: regiao.fretesSelecionados,
        }))
    },
  },
}
</script>
