<template>
  <validation-observer ref="observer">
    <form>
      <v-row justify="center">
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="email|required"
          >
            <v-text-field
              v-model="colab.user.email"
              :error-messages="errors"
              label="Email"
              prepend-icon="mdi-email"
              hint="O representante usará este email para acessar o sistema"
              persistent-hint
              required
              :readonly="readOnly"
              :disabled="readOnly"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <template
        v-if="!(colaboradorJaCadastrado || usuarioJaCadastrado) && !editar"
      >
        <v-row justify="center">
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              vid="password"
              name="Senha"
              :rules="{ required: true, regex }"
            >
              <v-text-field
                v-model="pass"
                type="password"
                :error-messages="errors"
                label="Senha"
                prepend-icon="mdi-lock"
                hint="A senha deve conter pelo menos 8 caracteres. Dentre eles deve conter pelo menos 1 letra maíuscula, 1 letra minúscula e 1 número."
                persistent-hint
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="Confirmação de Senha"
              rules="required|confirmed:password"
            >
              <v-text-field
                v-model="colab.user.password"
                type="password"
                :error-messages="errors"
                label="Confirmação de Senha"
                prepend-icon="mdi-lock"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </template>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: 'FormInformacaoAcesso',

  props: {
    colaborador: {
      type: Object,
      required: true,
    },
    editar: Boolean,
  },

  data: function () {
    return {
      regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      pass: '',

      colab: this.colaborador,
    }
  },

  computed: {
    colaboradorJaCadastrado: function () {
      return !!this.colab.public_id
    },
    usuarioJaCadastrado: function () {
      return this.colab.user && !!this.colab.user.public_id
    },
    readOnly: function () {
      return (
        (this.colaboradorJaCadastrado || this.usuarioJaCadastrado) &&
        !this.editar
      )
    },
  },

  methods: {
    validate: async function () {
      return await this.$refs.observer.validate()
    },
  },
}
</script>
