var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer"},[_c('form',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"regioes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.representantePublicID ? _vm.regioesFiltradas : _vm.regioes,"error-messages":errors,"label":"Regiões","prepend-icon":"mdi-earth","append-outer-icon":"mdi-refresh","hint":"Selecione as regiões que serão representadas","persistent-hint":"","multiple":"","required":"","solo":""},on:{"click:append-outer":function($event){return _vm.updateRegioes()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',_vm._g({attrs:{"input-value":item.selecionado},on:{"change":function () { return (item.selecionado = !item.selecionado); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"ml-2",domProps:{"textContent":_vm._s(item.sigla)}})]},proxy:true}],null,true)},on))],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex justify-space-between align-center"},[_c('v-spacer'),_c('validation-provider',{attrs:{"name":"tipo de frete","immediate":"","rules":{ required: !!item.selecionado }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticStyle:{"max-width":"200px"},attrs:{"items":item.tipo_frete,"error-messages":errors,"label":"Tipo de Frete","multiple":"","required":!!item.selecionado},model:{value:(item.fretesSelecionados),callback:function ($$v) {_vm.$set(item, "fretesSelecionados", $$v)},expression:"item.fretesSelecionados"}})]}}],null,true)})],1)],1)],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{domProps:{"textContent":_vm._s(item.sigla)}})]}}],null,true),model:{value:(_vm.representante.regioes),callback:function ($$v) {_vm.$set(_vm.representante, "regioes", $$v)},expression:"representante.regioes"}})]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }