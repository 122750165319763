<template>
  <validation-observer ref="observer">
    <form>
      <v-row justify="center">
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            name="regioes"
            rules="required"
          >
            <v-select
              v-model="representante.regioes"
              :items="representantePublicID ? regioesFiltradas : regioes"
              :error-messages="errors"
              label="Regiões"
              prepend-icon="mdi-earth"
              append-outer-icon="mdi-refresh"
              @click:append-outer="updateRegioes()"
              hint="Selecione as regiões que serão representadas"
              persistent-hint
              multiple
              required
              solo
            >
              <template v-slot:item="{ item, on }">
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox
                      v-on="on"
                      :input-value="item.selecionado"
                      @change="() => (item.selecionado = !item.selecionado)"
                    >
                      <template #label>
                        <span class="ml-2" v-text="item.sigla" />
                      </template>
                    </v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      class="d-flex justify-space-between align-center"
                    >
                      <v-spacer />
                      <validation-provider
                        v-slot="{ errors }"
                        name="tipo de frete"
                        immediate
                        :rules="{ required: !!item.selecionado }"
                      >
                        <v-select
                          v-model="item.fretesSelecionados"
                          style="max-width: 200px"
                          :items="item.tipo_frete"
                          :error-messages="errors"
                          label="Tipo de Frete"
                          multiple
                          :required="!!item.selecionado"
                      /></validation-provider>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template #selection="{ item }">
                <v-chip v-text="item.sigla" />
              </template>
            </v-select>
          </validation-provider>
        </v-col>
      </v-row>
    </form>
  </validation-observer>
</template>


<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'FormRegioes',

  props: {
    representanteTerritorial: {
      type: Object,
      required: true,
    },
    representantePublicID: String,
  },

  data: function () {
    return {
      representante: this.representanteTerritorial,
      regioesFiltradas: [],
    }
  },

  computed: {
    ...mapState('patio', ['regioes']),
  },

  created: async function () {
    if (this.representantePublicID) {
      const representante = await this.getRepresentante(
        this.representantePublicID
      )

      await this.filtrarRegioesDoRepresentante(representante)
    } else {
      await this.getRegioes()
    }

    this.resetRegioes()
  },

  methods: {
    ...mapActions('patio', ['getRegioes', 'getRepresentante']),

    validate: async function () {
      return await this.$refs.observer.validate()
    },

    filtrarRegioesDoRepresentante: async function (representante) {
      await this.getRegioes()

      this.regioesFiltradas = this.regioes.filter(
        (regiao) => !representante.regioes.includes(regiao.sigla)
      )
    },

    updateRegioes: async function () {
      await this.getRegioes()

      this.resetRegioes()
    },

    resetRegioes: function () {
      this.representante.regioes = []
    },
  },
}
</script>