<template>
  <div>
    <confirm-dialog @confirm="confirmDialog" />
    <br />
    <v-data-table
      :headers="headers"
      :items="representantes"
      sort-by="colaborador.user.name"
      class="border"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Representantes Cadastrados(as)</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-icon v-if="canList" title="Atualizar" medium @click="getRepresentantes()"
            >mdi-refresh</v-icon
          >
          <v-spacer />
          <v-btn
            v-if="canCreate"
            color="primary"
            dark
            class="mb-2"
            @click="$emit('cadastrar', 'cadastrar')"
            >Novo(a) Representante</v-btn
          >
        </v-toolbar>
      </template>
      <template v-slot:[`item.colaborador.user.celular`]="{ item }">
        <span>{{
          item.colaborador.user.celular | VMask('(##) #####-####')
        }}</span>
      </template>
      <template v-slot:[`item.colaborador.user.cpf`]="{ item }">
        <span>{{ item.colaborador.user.cpf | VMask('###.###.###-##') }}</span>
      </template>
      <template v-slot:[`item.regioes`]="{ item }">
        <router-link
          :to="{
            name: 'representante-territorial',
            params: { representantePublicID: item.public_id },
          }"
        >
          <v-icon v-if="canList" title="Visualizar Regiões" small class="mr-2" color="black"
            >mdi-eye</v-icon
          >
        </router-link>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="canUpdate"
          title="Editar Representante"
          small
          class="mr-2"
          color="black"
          @click="$emit('editar', item)"
          >mdi-square-edit-outline</v-icon
        >
        <v-icon
          v-if="canDelete"
          title="Remover Representante"
          small
          color="black"
          @click="deleteItem(item)"
          >mdi-delete</v-icon
        >
      </template>
      <template #no-data v-if="!canList">
          <span class="red--text"
            >Você não tem permissão para visualizar as informações desta
            tela</span
          >
        </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'ListarRepresentantes',
  components: { ConfirmDialog },
  data: function () {
    return {
      dialog: false,
      confirmDialogItem: {},
      representante: {},
      headers: [
        { text: 'Nome', value: 'colaborador.user.name' },
        { text: 'Email', value: 'colaborador.user.email' },
        { text: 'Telefone', value: 'colaborador.user.celular' },
        { text: 'CPF', value: 'colaborador.user.cpf' },
        { text: 'Regiões', value: 'regioes' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
    }
  },
  created: async function () {
    if (this.canList) {
      await this.getRepresentantes()
    }
  },
  computed: {
    ...mapState('patio', ['representantes']),

    permission() {
      return Sequences.Representantes.toString()
    },

    canList() {
      return this.$canDo(
        BasicPermissions.VISUALIZAR,
        this.permission
      )
    },
    canCreate() {
      return this.$canDo(
        BasicPermissions.CADASTRAR,
        this.permission
      )
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(
        BasicPermissions.EXCLUIR,
        this.permission
      )
    },
  },
  methods: {
    ...mapActions('patio', ['getRepresentantes', 'removerRepresentante']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    visualizarRegioes: function (representante) {
      this.dialog = true
      this.representante = representante
    },
    fecharVisualizacaoRegioes: function () {
      this.dialog = false
      this.representante = {}
    },
    deleteItem: function (item) {
      this.confirmDialogItem = item
      this.setDialogMessage({
        title: 'Excluir Representante',
        message: `Deseja realmente excluir o representante ${item.colaborador.user.name.toUpperCase()}?`,
      })
    },
    confirmDialog: async function () {
      try {
        await this.removerRepresentante(this.confirmDialogItem)
        this.successMessage('Representante excluído(a) com sucesso!')
        this.confirmDialogItem = {}
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>