<template>
  <v-card class="pb-4">
    <v-card-title class="justify-center">Editar Representante</v-card-title>
    <v-card-text>
      <form-informacao-pessoal
        :colaborador="representante.colaborador"
        editar
        ref="formInformacaoPessoal"
      />
      <form-informacao-acesso
        :colaborador="representante.colaborador"
        editar
        ref="formInformacaoAcesso"
      />
    </v-card-text>
    <v-row justify="center">
      <v-col cols="6">
        <v-card-actions class="d-flex justify-space-between">
          <buttons-cadastro @save="save" @close="close" value="Atualizar" />
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { cpf } from 'cpf-cnpj-validator'

import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'
import FormInformacaoPessoal from './form/FormInformacaoPessoal'
import FormInformacaoAcesso from './form/FormInformacaoAcesso'

export default {
  name: 'EditarRepresentante',

  props: {
    representanteASerEditado: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      representante: this.representanteASerEditado,
    }
  },

  components: {
    FormInformacaoPessoal,
    FormInformacaoAcesso,
    ButtonsCadastro,
  },

  computed: {
    cleanCPF: function () {
      return cpf.strip(this.representante.colaborador.user.cpf)
    },
  },

  methods: {
    ...mapActions('usuario', ['editarUsuario']),
    ...mapActions('template', ['successMessage', 'errorMessage']),

    close: function () {
      this.$emit('close')
    },
    save: async function () {
      if (await this.validate()) {
        try {
          const { user } = this.representante.colaborador

          await this.editarUsuario({
            ...user,
            cpf: this.cleanCPF,
          })
          this.successMessage('Representante atualizado(a) com sucesso!')
          this.close()
        } catch (e) {
          this.errorMessage(e.response.data)
        }
      }
    },

    validate: async function () {
      const isInformacaoPessoalValid =
        await this.$refs.formInformacaoPessoal.validate()
      const isInformacaoAcessoValid =
        await this.$refs.formInformacaoAcesso.validate()

      return isInformacaoPessoalValid && isInformacaoAcessoValid
    },
  },
}
</script>
