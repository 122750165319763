var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer"},[_c('form',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-earth","label":"Nacionalidade","item-text":"pais","item-value":"pais","items":_vm.nacionalidades,"readonly":_vm.editar,"disabled":_vm.editar},model:{value:(_vm.colab.user.nacionalidade),callback:function ($$v) {_vm.$set(_vm.colab.user, "nacionalidade", $$v)},expression:"colab.user.nacionalidade"}}),_c('validation-provider',{ref:"cpf",attrs:{"rules":{
            required: true,
            cpfValido: _vm.colab.user.nacionalidade === 'Brasil',
          },"name":"cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.docMask),expression:"docMask"}],attrs:{"error-messages":errors,"label":"CPF/Documento","prepend-icon":"mdi-card-account-details","required":"","readonly":_vm.editar,"disabled":_vm.editar},on:{"change":_vm.setColaboradorIfAlreadyExists},model:{value:(_vm.colab.user.cpf),callback:function ($$v) {_vm.$set(_vm.colab.user, "cpf", $$v)},expression:"colab.user.cpf"}})]}}])})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"nome","rules":_vm.readOnly ? 'alpha_spaces' : 'alpha_spaces|required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Nome","prepend-icon":"mdi-card-account-details","required":"","readonly":_vm.readOnly,"disabled":_vm.readOnly},model:{value:(_vm.colab.user.name),callback:function ($$v) {_vm.$set(_vm.colab.user, "name", $$v)},expression:"colab.user.name"}})]}}])})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"telefone","rules":{
            required: !_vm.colaboradorJaCadastrado,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) #####-####']),expression:"['(##) #####-####']"}],attrs:{"error-messages":errors,"label":"Telefone","prepend-icon":"mdi-phone","required":"","readonly":_vm.readOnly,"disabled":_vm.readOnly},model:{value:(_vm.colab.user.celular),callback:function ($$v) {_vm.$set(_vm.colab.user, "celular", $$v)},expression:"colab.user.celular"}})]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }