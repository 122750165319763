<template>
  <validation-observer ref="observer">
    <form>
      <v-row justify="center">
        <v-col cols="6">
          <v-select
            prepend-icon="mdi-earth"
            v-model="colab.user.nacionalidade"
            label="Nacionalidade"
            item-text="pais"
            item-value="pais"
            :items="nacionalidades"
            :readonly="editar"
            :disabled="editar"
          />
          <validation-provider
            v-slot="{ errors }"
            :rules="{
              required: true,
              cpfValido: colab.user.nacionalidade === 'Brasil',
            }"
            name="cpf"
            ref="cpf"
          >
            <v-text-field
              @change="setColaboradorIfAlreadyExists"
              v-model="colab.user.cpf"
              v-mask="docMask"
              :error-messages="errors"
              label="CPF/Documento"
              prepend-icon="mdi-card-account-details"
              required
              :readonly="editar"
              :disabled="editar"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            name="nome"
            :rules="readOnly ? 'alpha_spaces' : 'alpha_spaces|required'"
          >
            <v-text-field
              v-model="colab.user.name"
              :error-messages="errors"
              label="Nome"
              prepend-icon="mdi-card-account-details"
              required
              :readonly="readOnly"
              :disabled="readOnly"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            name="telefone"
            :rules="{
              required: !colaboradorJaCadastrado,
            }"
          >
            <v-text-field
              v-model="colab.user.celular"
              v-mask="['(##) #####-####']"
              :error-messages="errors"
              label="Telefone"
              prepend-icon="mdi-phone"
              required
              :readonly="readOnly"
              :disabled="readOnly"
            />
          </validation-provider>
        </v-col>
      </v-row>
    </form>
  </validation-observer>
</template>

<script>
import { cpf } from 'cpf-cnpj-validator'
import { mapActions } from 'vuex'

import AuthService from '@/services/auth.service'

export default {
  name: 'FormInformacaoPessoal',

  props: {
    colaborador: {
      type: Object,
      required: true,
    },
    editar: Boolean,
  },

  data: function () {
    return {
      colab: this.colaborador,
      hasBackendErrors: false,
      nacionalidades: [
        { pais: 'Brasil' },
        { pais: 'Paraguai' },
        { pais: 'Argentina' },
        { pais: 'Uruguay' },
      ],
    }
  },

  computed: {
    docMask() {
      switch (this.colab.user.nacionalidade) {
        case 'Brasil':
          return '###.###.###-##'
        case 'Paraguai':
          return '#######'
        case 'Argentina':
          return '##.###.###'
        case 'Uruguay':
          return '#.###.###.#'
        default:
          return '###########'
      }
    },
    cleanCPF: function () {
      return cpf.strip(this.colaborador.user.cpf)
    },
    colaboradorInicial: function () {
      return {
        grupo: '',
        public_id: '',
        user: {
          cpf: this.cleanCPF,
          nacionalidade: this.colab.user.nacionalidade || '',
        },
      }
    },
    colaboradorJaCadastrado: function () {
      return !!this.colab.public_id
    },
    usuarioJaCadastrado: function () {
      return this.colab.user && !!this.colab.user.public_id
    },
    readOnly: function () {
      return (
        (this.colaboradorJaCadastrado || this.usuarioJaCadastrado) &&
        !this.editar
      )
    },
  },

  methods: {
    ...mapActions('patio', ['getRepresentanteByCPF']),
    ...mapActions('gerencial', ['getColaboradorByCPF', 'addColaborador']),

    validate: async function () {
      const { observer } = this.$refs
      const isFormValid = await observer.validate()

      this.hasBackendErrors = false

      if (!this.editar && isFormValid) {
        if (await this.isRepresentanteAlreadyExists()) {
          observer.setErrors({
            cpf: 'Um representante com este CPF já existe.',
          })
          this.hasBackendErrors = true
        }
      }

      return isFormValid && !this.hasBackendErrors
    },

    isRepresentanteAlreadyExists: async function () {
      if (this.cleanCPF) {
        const data = await this.getRepresentanteByCPF(this.cleanCPF)

        return data.length
      }
    },

    setColaboradorIfAlreadyExists: async function () {
      this.reset()

      const isValidCPF = this.$refs.cpf.flags.valid

      if (isValidCPF && this.cleanCPF) {
        let data = await this.getColaboradorByCPF(this.cleanCPF)

        if (!data.length) {
          data = await AuthService.getUserByParams({ cpf: this.cleanCPF })
          if (data.length) {
            Object.assign(this.colab, { user: data[0] })
          }
        } else {
          Object.assign(this.colab, data[0])
        }
      }
    },

    reset: function () {
      Object.assign(this.colab, this.colaboradorInicial)
    },
  },
}
</script>
