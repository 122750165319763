var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirm-dialog',{on:{"confirm":_vm.confirmDialog}}),_c('br'),_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.representantes,"sort-by":"colaborador.user.name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Representantes Cadastrados(as)")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.canList)?_c('v-icon',{attrs:{"title":"Atualizar","medium":""},on:{"click":function($event){return _vm.getRepresentantes()}}},[_vm._v("mdi-refresh")]):_vm._e(),_c('v-spacer'),(_vm.canCreate)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$emit('cadastrar', 'cadastrar')}}},[_vm._v("Novo(a) Representante")]):_vm._e()],1)]},proxy:true},{key:"item.colaborador.user.celular",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("VMask")(item.colaborador.user.celular,'(##) #####-####')))])]}},{key:"item.colaborador.user.cpf",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("VMask")(item.colaborador.user.cpf,'###.###.###-##')))])]}},{key:"item.regioes",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'representante-territorial',
          params: { representantePublicID: item.public_id },
        }}},[(_vm.canList)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Visualizar Regiões","small":"","color":"black"}},[_vm._v("mdi-eye")]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Editar Representante","small":"","color":"black"},on:{"click":function($event){return _vm.$emit('editar', item)}}},[_vm._v("mdi-square-edit-outline")]):_vm._e(),(_vm.canDelete)?_c('v-icon',{attrs:{"title":"Remover Representante","small":"","color":"black"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }